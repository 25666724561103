import React, { useEffect, useState, version } from "react";
import "../css/user.css";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import ButtonsMenu from "./ButtonsMenu";
import Attendence from "./Attendence";
import Atten from "./attendence/Atten";
import Information from "./Information";
import Petition from "./Petition";
import Suggestions from "./Suggestions";
import Week from "./Week";
import PassedWeek from "./Passedweek";
import ChangePhoto from "./ChangePhoto";

const User = () => {
    const [userInfo, setUserInfo] = useState(null);

    const [infoVesrion, setInfoVersion] = useState(null);

    const [buttonUpdate, setButtonUpdate] = useState(null);

    const close = ()=>{
        if(document.getElementsByClassName('menuSection')[0].classList.contains('show')){
            document.getElementsByClassName('menuSection')[0].classList.remove('show');
            document.getElementsByClassName('menuSection')[0].classList.add('hidden');
        }
    }

    const btnMenu = () => {
        const menuSection = document.getElementsByClassName('menuSection')[0];

        if (menuSection.classList.contains('show')) {
            menuSection.classList.add('hidden');
            menuSection.classList.remove('show');
        } else {
            menuSection.classList.add('show');
            menuSection.classList.remove('hidden');
        }
    }

    const informationUser = async () => {
        const key001ms = localStorage.getItem('Key001ms');

        const data = {
            key001ms
        }

        try {
            const response = await fetch('https://webtestfull2.online/information_user.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();

            if (result.status === 'success') {
                setUserInfo(result);
            }

        } catch (error) {
            console.error('Error fetching user information:', error);
        }
    }

    const updateVersion = async ()=>{

        const versionGet = localStorage.getItem('vesrion');
        const data = {
            version: true,
            versionGet
        }
        try {
            const response = await fetch('https://webtestfull2.online/verifyVersion.php',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();
            console.log(result);
            if(result.status === 'assing'){
                const versionNew = result.version;
                setInfoVersion(versionNew);
                console.log(versionNew);
                setButtonUpdate("Actualizar ahora");
                const clickUpdate = document.getElementById('clickUpdate');
                document.getElementById('updatePanelApp').style.display = 'flex';
                clickUpdate.addEventListener('click', ()=>{
                    setTimeout(() => {
                        setButtonUpdate("Acvtualizando...");
                    },2000);

                    localStorage.setItem('vesrion', result.version);
                    window.location.reload();
                });
            }
        } catch (error) {
            console.error('Ha ocurrido un error en elsiatema: ',error)
        }
    }

    useEffect(() => {
        informationUser();
        updateVersion();
    }, []);

    return (
        <BrowserRouter>
            <div className="user">
                {/* Header */}
                <div id="headerUser">
                    <h2>FAVEN APP</h2> <button id="btnMenu" onClick={btnMenu}>☰</button>
                </div>

                <div className="menuSection">
                    <NavLink className={"btnbbuu"} to="/" onClick={close}>Inicio</NavLink>
                    <NavLink className={"btnbbuu"} to="/changePhoto" onClick={close}>Cambiar ajustes:</NavLink>
                    <button className={"btnbbuu"}>Salir</button>
                </div>

                {/* Section */}
                <div id="sectionUser">

                    {/* Profile user */}
                    <div id="profileUser">
                        {userInfo && (
                            <>
                                <img 
                                    src={userInfo.photo ? `data:image/jpeg;base64,${userInfo.photo}` : `../../img/backgroundmovil.jpg`} 
                                    alt="User Profile" 
                                />
                                <h2>{userInfo.name}</h2>
                            </>
                        )}
                    </div>

                    {/* Buttons menu */}
                    <Routes>
                        <Route path="/" element={<ButtonsMenu />} />
                        <Route path="/attendence" element={<Attendence />} />
                        <Route path="/atten/:paramvalue" element={<Atten />} />
                        <Route path="/information" element={<Information/>} />
                        <Route path="/petition" element={<Petition/>} />
                        <Route path="/suggestions" element={<Suggestions/>} />
                        <Route path="/week" element={<Week/>} />
                        <Route path="/passedweek" element={<PassedWeek/>} />
                        <Route path="/changePhoto" element={<ChangePhoto/>} />
                    </Routes>

                </div>
                <div id="updatePanelApp">
                    <h2>Hay una nueva Actualizacion</h2>
                    <h3>Version: {infoVesrion}</h3>
                    <button id="clickUpdate">{buttonUpdate}</button>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default User;
