import React from "react";
import "../css/buttonsmenu.css";
import { NavLink } from "react-router-dom";

const ButtonsMenu = () => {

    const exit = ()=>{
        localStorage.clear();
        window.location.reload();
    }

    return (
        <div className={"buttonsMenu"}>
            <NavLink id="attendence" className={"buu"} to={"/attendence"}>Asistencia</NavLink>
            <NavLink id="week" className={"buu"}to={"/week"}>Semana</NavLink>
            <NavLink id="passedWeek" className={"buu"} to={"/passedweek"}>Semana pasada</NavLink>
            <NavLink id="byDate" className={"buu"}>Por fecha</NavLink>
            <NavLink id="history" className={"buu"}>Historial (Horas)</NavLink>
            <NavLink id="infor" className={"buu"} to={"/information"}>Informacion</NavLink>
            <NavLink id="petition" className={"buu"} to={"/petition"}>Peticion</NavLink>
            <NavLink id="suggestions" className={"buu"} to={"/suggestions"}>sugerencias</NavLink>
            <NavLink id="exit" className={"buu"} onClick={exit}>Salir</NavLink>
        </div>
    );
}

export default ButtonsMenu;