import React, { useState } from "react";
import "../css/suggestions.css";
import IMG from "../../img/ok.gif";

const Suggestions = () => {
    const [message, setMessage] = useState(null);

    const sendFormSuggestions = async (e) => {
        e.preventDefault();

        document.getElementById('message__').style.display = 'flex';
        setMessage('Cargando...');

        const textSuggestions = document.getElementById('textSuggestions').value;
        const id = localStorage.getItem('Key001ms');

        const data = {
            textSuggestions,
            id,
        };

        console.log(data);

        try {
            const response = await fetch('https://webtestfull2.online/suggestions.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();

            console.log(result);
            setMessage(result.message);
            document.getElementById('sub__').disabled = true;
            if(result.status === 'success'){
                document.getElementById('imag__').style.display = 'block';
            }
        } catch (error) {
            console.log('No se puedo enviar la peticion al servidor');
        }
    };

    return (
        <div className="suggestions">
            <h2>Buzón de sugerencias</h2>
            <form id="formSuggestions" onSubmit={sendFormSuggestions}>
                <label>Envio de sugerencias: </label>
                <textarea id="textSuggestions" placeholder="Escribe qué aspecto se debe mejorar" spellCheck="false" />
                <input type="submit" id="sub__" value={"Enviar"} />
                <div id="message__">
                    <h2 id="h2w__">{message}</h2>
                    <img src={IMG} id="imag__" />
                </div>
            </form>
        </div>
    );
}

export default Suggestions;
